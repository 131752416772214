import PropTypes from 'prop-types';
import React, { Component } from 'react';

import MobileAppBanner from './mobile/MobileAppBanner';

const bannerLinks = {
  tportal: {
    android: 'https://play.google.com/store/apps/details?id=com.appfolio.resident_portal&utm_source=passport_login',
    ios: 'https://itunes.apple.com/us/app/online-portal-by-appfolio/id1376350358?mt=8',
  },
  property: {
    android: 'https://play.google.com/store/apps/details?id=com.appfolio.appfolio_property_manager&utm_source=passport_login',
    ios: 'https://itunes.apple.com/us/app/appfolio-property-manager/id1057763031?mt=8',
  },
};

class LoginFormFooter extends Component {
  renderBannerForIdp() {
    if (this.props.idpType === 'tportal') {
      return (
        <MobileAppBanner
          androidLink={bannerLinks.tportal.android}
          iosLink={bannerLinks.tportal.ios}
        />
      );
    } else if (this.props.idpType === 'property') {
      return (
        <MobileAppBanner
          androidLink={bannerLinks.property.android}
          iosLink={bannerLinks.property.ios}
        />
      );
    } else if (this.props.idpType === 'vendor') {
      return (
        <div className="text-center mt-5">
          <a href="https://www.appfolio.com/help/vendor-portal" target="_blank" rel="noreferrer">Need Help?</a>
        </div>
      );
    }
    return null;
  }

  render() {
    return this.props.idpType ? this.renderBannerForIdp() : null;
  }
}

LoginFormFooter.propTypes = {
  idpType: PropTypes.oneOf(['', 'tportal', 'property', 'oportal', 'vendor', 'internal', 'identity']),
};

LoginFormFooter.defaultProps = {
  idpType: '',
};

export default LoginFormFooter;
